import React, { useEffect, useState } from "react";
import { Badge, Button, Empty, Skeleton, Tooltip, Typography } from "antd";
import styled from "styled-components";
import { TinyArea } from "@ant-design/charts";
import {
  goalBasedApi,
  useGetGoalBasedPlanQuery,
  useGetSelfMadeQuery,
  useGetSmartfolioQuery,
} from "src/store/Goal-Based/goalBasedApi";
import { SmartfolioBuilder } from "../components/SmartfolioBuilder/SmartfolioBuilder";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "src/store/rootReducer";
import { useHistory } from "react-router";
import RadioList from "../components/RadioOptions";
import { useGetRecommendedSmartfoliosQuery } from "src/store/middleware/api_v2";
import { Pending } from "../icons/Pending";
import { theme } from "src/theme/theme";
import { ExportOutlined } from "@ant-design/icons";
import { ConfirmationPopup } from "../components/ConfirmationPopup/ConfirmationPopup";
import { setSmartfolioSelected } from "src/store/Goal-Based/MenuSlice";

const { Title, Text, Link } = Typography;

interface SmartfolioCardProps {
  display_name: string;
  description: string;
  id?: number;
  nav?: { [date: string]: number };
  source?: string;
  onSelectSmartfolio: (smartfolioId: number) => void;
  selectedSmartfolioId?: number;
}
export const StyledBadge = styled.span`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  padding: 1px 12px;
  border-radius: 16px;
  margin: 4px;
  gap: 6px;
  user-select: none;
  font-size: 14px;
`;

interface Props {
  color: string;
  bgColor: string;
  name: string;
}

export function TitleBadge({ color, bgColor, name }: Props) {
  return (
    <StyledBadge color={color} bgColor={bgColor}>
      <Badge
        color={color}
        text="Based on"
        style={{ fontSize: "10px", fontWeight: "normal", color }}
      />
      <span> {name}</span>
    </StyledBadge>
  );
}

export const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

export const CustomTinyLineText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #929fac;
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledText = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmartfolioCard: React.FC<SmartfolioCardProps> = ({
  display_name,
  description,
  nav,
  id,
  source,
  onSelectSmartfolio,
  selectedSmartfolioId
}) => {
  const history = useHistory();

  const { currentState: selectedMenu } = useSelector(
    (state: RootStateType) => state.menuReducer,
  );
  const input = useSelector(
    (state: RootStateType) => state[`${selectedMenu}Reducer`],
  );

  const [getPlan] = goalBasedApi[`useLazyGet${selectedMenu}StatsQuery`]();

  return (
    <ConditionalWrap
      condition={!nav}
      wrap={(wrappedChildren) => (
        <Tooltip title="We're currently in the creation phase.Please stay tuned, and we'll notify you as soon as it's ready for you to explore.">
          {wrappedChildren}
        </Tooltip>
      )}
    >
      <StyledCard
        blocked={false}
        style={{
          background: selectedSmartfolioId === id ? theme.colors.primary[25] : theme.colors.primary[10],
          border: `2px solid ${selectedSmartfolioId === id ? theme.colors.primary[50] : 'transparent'}`
        }}
        onClick={() => { onSelectSmartfolio(id) }}
      >
        <StyledText>
          <div
            style={{
              margin: "0", fontSize: "1.3em", fontWeight: "700"
            }}
          >
            <Link
              style={{
                display: "inline-flex",
                alignItems: "flex-start", // Align children elements to the top
              }}
              onClick={async (e) => {
                e.stopPropagation();
                const res = await getPlan({ input, option: "true", id }).unwrap();
                window.location.href = `goal-predictions/${res?.planId}`;
              }}>
              {display_name}
              {"  "}
              {source &&
                source !== "USER-DEFINED" &&
                (!nav ? (
                  <TitleBadge
                    color="#F29811"
                    bgColor="rgba(242, 152, 17, 0.10)"
                    name={`${process.env.REACT_APP_BANK_NAME || ""
                      } Capital Smartfolio`}
                  />
                ) : (
                  <TitleBadge
                    color={theme.colors.primary[500]}
                    bgColor={theme.colors.primary[30]}
                    name={`${process.env.REACT_APP_BANK_NAME || ""
                      } Capital Smartfolio`}
                  />
                ))}
              <ExportOutlined style={{ fontSize: '12px', paddingLeft: '4px' }} />
            </Link>

          </div>
          <Text style={{ color: selectedSmartfolioId === id ? "#1890ff" : "#565656" }}>{description}</Text>
        </StyledText>

        {!!nav ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomTinyLineText>Last Month</CustomTinyLineText>
            <TinyLineChart data={Object.values(nav)} />
          </div>
        ) : (
          <div style={{ paddingRight: "1rem" }}>
            <Pending />
          </div>
        )}
      </StyledCard>
    </ConditionalWrap>
  );
};

const CustomSkeleton = styled(Skeleton)`
  .ant-skeleton-title {
    margin: 0;
  }
  .ant-skeleton-paragraph {
    margin: 0;
  }
`;

const SkeletonItem = () => {
  return (
    <StyledSkeleton>
      <CustomSkeleton active paragraph={{ rows: 1 }} />
    </StyledSkeleton>
  );
};

const SkeletonList = () => {
  return (
    <>
      <SkeletonItem />
      <SkeletonItem />
    </>
  );
};

const StyledSmartfoliosSection = styled.div`
  display: flex;
  gap: 1.2rem;
  flex-direction: row;
  @media (max-width: 2000px) {
    flex-direction: column;
  }
`;

const options = [
  { label: "Retirement Lump Sum", value: "retirementLumpSum" },
  { label: "Retirement Monthly Income", value: "retirementMonthlyIncome" },
  { label: "Short term", value: "shortTerm" },
  { label: "Property", value: "property" },
  { label: "Business", value: "business" },
  { label: "Education", value: "education" },
  { label: "Debt", value: "debt" },
  { label: "Inheritence", value: "inheritance" },
  { label: "No specific goal", value: "none" },
];

const SmartfolioList: React.FC = () => {
  const { data, isFetching, refetch } = useGetRecommendedSmartfoliosQuery({
    page_size: 100,
    page_number: 1,
    section: "GOAL-BASED-INVESTMENT",
  });

  const {
    data: selfMade,
    isLoading: selfMadeLoading,
    isFetching: selfMadeFetching,
  } = useGetSelfMadeQuery();
  const [visible, setVisible] = useState(false);

  const [selectedSmartfolio, setSelectedSmartfolio] = useState<number | null>(null);
  const [planId, setPlanId] = useState<any>();

  const dispatch = useDispatch();

  const handleSelect = (smartfolioId: number) => {
    setSelectedSmartfolio(smartfolioId);
    dispatch(setSmartfolioSelected({ isSmartfolioSelected: true }));
  }

  const { currentState: selectedMenu } = useSelector(
    (state: RootStateType) => state.menuReducer,
  );

  const input = useSelector(
    (state: RootStateType) => state[`${selectedMenu}Reducer`],
  );

  const [getPlan] = goalBasedApi[`useLazyGet${selectedMenu}StatsQuery`]();

  const [confirmationPopupVisible, setConfirmationPopupVisible] =
    useState(false);

  const openConfirmationPopup = (value: boolean) => {
    setConfirmationPopupVisible(value);
  };

  const plan = useGetGoalBasedPlanQuery(planId);
  const smartfolioInfo = useGetSmartfolioQuery(planId);
  const menuState = useSelector((state: RootStateType) => state.menuReducer);

  useEffect(() => {
    if (menuState.isInvestClicked === true)
      handleInvestClick()
  }, [menuState.isInvestClicked])

  async function handleInvestClick() {
    try {
      const res = await getPlan({ input, option: "true", id: selectedSmartfolio }).unwrap();
      if (res) {
        setPlanId(res?.planId);
        openConfirmationPopup(true)
      }
    } catch (error) {
      console.log('Fetch error:', error);
    }
  }


  return (
    <>
      <StyledSmartfoliosSection>
        <div style={{ flexBasis: "50%" }}>
          <SmartfolioBuilder visible={visible} setVisible={setVisible} />
          <Title
            style={{
              fontWeight: 700,
              fontSize: "1.7em",
            }}
            level={2}
          >
            Define your objective for this investment
          </Title>
          <RadioList options={options} refetch={refetch} />
          <Title
            style={{
              fontWeight: 700,
              fontSize: "1.7em",
            }}
            level={2}
          >
            Smartfolios
          </Title>
          <StyledSmartfolioList>
            {isFetching ? (
              <SkeletonList />
            ) : data?.count === 0 ? (
              <Empty />
            ) : (
              data?.data
                ?.filter(
                  (item) => item?.object_content?.display_name != undefined,
                )
                .map(({ id, object_content }) => {
                  const { display_name, description, performances } =
                    object_content;
                  return (
                    <SmartfolioCard
                      id={object_content.id}
                      key={id}
                      display_name={display_name}
                      description={description}
                      nav={performances?.spark_line_12_month}
                      onSelectSmartfolio={handleSelect}
                      selectedSmartfolioId={selectedSmartfolio}
                    />
                  );
                })
            )}
          </StyledSmartfolioList>
        </div>
        <div style={{ flexBasis: "50%" }}>
          <div style={{ display: "inline-flex", justifyContent: "space-between", width: "100%" }}>
            <Title level={2} style={{ fontWeight: 700, fontSize: "1.7em" }}>
              Self-Made Smartfolios
            </Title>
            <Title
              onClick={() => {
                setVisible(true);
              }}
              level={2}
              style={{
                color: theme.colors.primary[500],
                marginTop: 0,
                fontWeight: 700,
                fontSize: "1.4em",
                cursor: "pointer"
              }}
            >
              Build Your Smartfolio
            </Title>

          </div>
          {/* <ClickableDottedArea
          onClick={() => {
            setVisible(true);
          }}
        >
          <Title
            style={{
              color: theme.colors.primary[500],
              margin: 0,
              fontSize: "1.3em",
              fontWeight: 700,
            }}
          >
            Build Your Smartfolio
          </Title>
          <Text style={{ color: "#565656" }}>
            Pick your favourite security and build your own Smartfolio
          </Text>
        </ClickableDottedArea> */}
          <StyledSmartfolioList>
            {selfMadeFetching ? (
              <SkeletonList />
            ) : selfMade?.count === 0 ? (
              <Empty />
            ) : (
              selfMade?.data?.map((smartfolio) => (
                <SmartfolioCard
                  id={smartfolio?.id}
                  key={smartfolio?.id}
                  display_name={smartfolio?.display_name}
                  description={smartfolio?.description}
                  nav={smartfolio?.performances.spark_line_12_month}
                  source={smartfolio?.source}
                  onSelectSmartfolio={setSelectedSmartfolio}
                  selectedSmartfolioId={selectedSmartfolio}
                />
              ))
            )}
          </StyledSmartfolioList>
        </div>
      </StyledSmartfoliosSection>
      <ConfirmationPopup
        setConfirmationPopupVisible={setConfirmationPopupVisible}
        planId={planId}
        visible={confirmationPopupVisible}
        onCancel={() => openConfirmationPopup(false)}
        data={{ smartfolio_managment_fees: smartfolioInfo?.data?.managment_fees, ...plan.data }}
      />
    </>
  );
};

const StyledCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  color: #0000;
  padding: 1em;
  border-radius: 0.7em;
  cursor: ${({ blocked }) => (blocked ? "wait" : "pointer")};
`;

const StyledSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  background: ${theme.colors.primary[10]};
  padding: 1em;
  border-radius: 0.7em;
  min-height: 6rem;
`;

const ClickableDottedArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0;
  justify-content: center;
  background: ${theme.colors.primary[10]};
  border: 2px dashed #dbe4ed;
  cursor: pointer;
  border-radius: 0.7em;
  padding: 1em;
  height: 9em;
  margin-bottom: 1rem;
`;

const StyledSmartfolioList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 40rem;

  /* Hide the scrollbar by default */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
  }

  /* Show the scrollbar when hovering over the container */
  &:hover {
    scrollbar-width: thin;
    -ms-overflow-style: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
  }
`;

const TinyLineChart: React.FC<{ data: number[] }> = ({ data }) => {
  const config = {
    height: 40,
    autoFit: false,
    data,
    area: {
      style: {
        fill: `l(270) 0:${data[0] < data[data.length - 1]
          ? theme.colors.primary["10"]
          : "1:#f0443826"
          } 1:${data[0] < data[data.length - 1]
            ? theme.colors.primary[500]
            : "1:#f0443826"
          }`, // Use a linear gradient for the area
        shadowColor: theme.colors.primary[500],
      },
    },
    line: {
      style: {
        stroke:
          data[0] < data[data.length - 1]
            ? theme.colors.primary[500]
            : "#F04438",
        lineWidth: 2,
      },
    },
    smooth: true,
    width: 150,
  };

  const minYValue = Math.min(...data.map((item) => item));
  return (
    <TinyArea
      {...config}
      yAxis={{
        min: minYValue, // Start y-axis from the minimum y-value
      }}
    />
  );
};

export default SmartfolioList;
